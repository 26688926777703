<template>
  <div id="news">
    <!-- <PageHeaderNews
      :pageHeader="computedPageHeader"
      :kvTitle1Color="kvTitle1Color"
      :kvTitle2Color="kvTitle2Color"
    /> -->
    <PageHeader
      :pageHeader="computedPageHeader"
      :kvTitle1Color="kvTitle1Color"
      :kvTitle2Color="kvTitle2Color"
      :newsOnly="newsOnly"
    />
    <router-view />
  </div>
</template>

<script>
import '@/assets/scss/news.scss';

import PageHeader from '@/components/PageHeader.vue';
// import PageHeaderNews from '@/components/PageHeaderNews.vue';
import { NewsKv } from '@/lib/const';
// import { articleIndex } from '@/lib/http';
import { mapGetters } from 'vuex';

export default {
  title: '學會訊息',
  name: 'NewsHome',
  data() {
    return {
      varList: [],
      ...NewsKv,
      kvTitle1Color: 'txt-light_green',
      kvTitle2Color: 'txt-dark',
      newsOnly: true,
    };
  },
  computed: {
    ...mapGetters([
      'newsType',
    ]),
    computedPageHeader() {
      const varListUpdate = this.newsType.map((item) => ({
        ...item,
        text: item.name,
        url: `/news/${item.name}`,
      }));
      return {
        ...NewsKv.pageHeader,
        listArray: [
          {
            id: '',
            url: '/news/全部',
            text: '全部',
          },
          ...varListUpdate,
        ],
      };
    },
  },
  components: {
    // PageHeaderNews,
    PageHeader,
  },
};
</script>
